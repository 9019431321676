.container {
    position: fixed;
    z-index: 101;
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 20px 32px;
    box-sizing: border-box;
    background-color: #fffef9;
    box-shadow: 0px 0px 4px rgba(46, 45, 43, 0.25);
    border-radius: 32px 32px 0px 0px;
}

.container img {
    align-self: center;
}

.container button {
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
}

.container p {
    margin: 0px;
}

.borough {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.progress {
    display: flex;
    padding: 24px 0px 16px;
}

.progressBar {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-right: 24px;
}

.back {
    margin-top: -8px;
    padding: 8px 8px 8px 0px;
    background: inherit;
}

.back p {
    padding-left: 8px;
    font-weight: 600;
    font-size: 16px;
    color: #847e68;
}

.viewing {
    padding-top: 12px;
    font-weight: 600;
    font-size: 18px;
    color: #847e68;
}

.name {
    padding: 4px 0px 12px;
    font-weight: 700;
    font-size: 36px;
    color: #2e2d2b;
}

.visits {
    display: flex;
}

.visits p {
    margin-right: 8px;
    padding: 8px 20px;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #fffef9;
    background: #e65402;
    border-radius: 18px;
}

.visits .gray {
    background: #847e68;
}

.address {
    display: flex;
    padding: 20px 0px 12px;
}

.address p {
    padding-left: 8px;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    color: #847e68;
}

.footer {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.footer > p {
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #2e2d2b;
}

.footer > button {
    padding: 8px 0px;
    background: inherit;
}

.footer > button > p {
    padding-right: 8px;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: #847e68;
}

.hidden {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 24px 0 -32px -32px;
    width: 100vw;
    height: auto;
    background-image: url("../../../assets/cloud_trim.svg");
}

.challenges {
    width: 300px;
    height: 108px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 40px 0px 16px;
    padding: 6px 12px 12px;
    box-sizing: border-box;
    background-color: #fdf1db;
    border: 6px solid #fffef9;
    border-radius: 16px;
}

.challenges > p {
    margin-top: 4px;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: #2e2d2b;
}

.challenges > p > b {
    color: #e65402;
}

.challengesButton {
    width: 300px;
    height: 60px;
    margin-bottom: 32px;
    font-weight: 600;
    font-size: 18px;
    color: #fffef9;
    background: #e65402;
    box-shadow: 0px 2px 0px #2e2d2b;
    border-radius: 30px;
}

.challengesButton > img {
    padding: 0px 0px 4px 8px;
}
