html,
body {
    margin: 0;
    font-family:
        "Inter",
        -apple-system,
        BlinkMacSystemFont,
        "Segoe UI",
        "Roboto",
        "Oxygen",
        "Ubuntu",
        "Cantarell",
        "Fira Sans",
        "Droid Sans",
        "Helvetica Neue",
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    box-sizing: border-box;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}

p {
    margin: 0px;
    padding: 0px;
    font-weight: 500;
    font-size: 18px;
}

img {
    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;
}

a {
    text-decoration: none;
}

#root {
    width: 100vw;
    width: -moz-available;
    width: -webkit-fill-available;
    width: stretch;
    height: 100vh;
    height: -moz-available;
    height: -webkit-fill-available;
    height: stretch;
}
