.container {
    display: flex;
    justify-content: space-between;
    margin-top: 16px;
    width: 100%;
}

.overview {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 108px;
}

.overview > p {
    line-height: 24px;
}

.overview > p:first-child,
.overview:last-child p {
    color: #847e68;
}

.points {
    display: flex;
    align-items: center;
    height: 48px;
}

.points > p {
    margin-left: 4px;
}

.bold_points {
    font-weight: 700;
    font-size: 36px;
    color: #2e2d2b;
}

.bold_events {
    font-weight: 700;
    font-size: 24px;
    color: #2e2d2b;
}

@media screen and (min-width: 601px) {
    .overview {
        min-width: 120px;
    }

    .overview:first-child {
        margin-right: 64px;
    }
}
