.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.container > button {
    position: absolute;
    z-index: 1;
    align-self: flex-end;
    padding: 8px 16px;
    border: none;
    border-radius: 16px;
    background-color: #847e68;
    font-weight: 700;
    font-size: 18px;
    color: #fffef9;
    cursor: pointer;
}

.badges {
    margin: 64px 0px 24px;
}

.small,
.large {
    display: flex;
    justify-content: center;
    margin-bottom: 70px;
    gap: 70px 40px;
}

.small,
.large > div {
    width: 160px;
}

.carousel_small {
    width: 100%;
    height: 280px;
}

.carousel_large {
    width: 80%;
    height: 280px;
}

.carousel_small > button,
.carousel_large > button {
    z-index: 1;
}

@media screen and (max-width: 600px) {
    .container {
        margin-top: -40px;
    }

    .container > button {
        display: none;
    }
}

@media screen and (max-width: 800px) {
    .small {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
    }

    .large {
        display: none;
    }
}

@media screen and (min-width: 801px) {
    .small {
        display: none;
    }
}
