.placeholder {
    padding: 0;
    margin: 0;
    font-weight: 700;
    font-size: 24px;
    color: #2e2d2b;
    text-align: center;
}

.container {
    box-sizing: inherit;
    background: #fffef9;
    border-radius: 16px;
    padding: 12px 80px;
    margin-bottom: 16px;
    display: flex;
    justify-content: center;
    gap: 48px;
    align-items: center;
}

@media screen and (max-width: 975px) {
    .container {
        padding: 12px;
    }
}

.text_container {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: left;
}

.label {
    padding: 0;
    margin: 0;
    font-weight: 700;
    font-size: 18px;
    color: rgba(46, 45, 43, 0.6);
}

.location {
    padding: 0;
    margin: 0;
    font-weight: 700;
    font-size: 36px;
    color: #2e2d2b;
}

.detailed_progression {
    display: flex;
    gap: 24px;
}

.detailed_progression div {
    margin: auto;
}

.progress_label {
    padding: 0;
    margin: 0;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
}

@media screen and (max-width: 800px) {
    .container {
        gap: 24px;
    }

    .label {
        font-size: 16px;
    }

    .location {
        font-size: 30px;
    }

    .detailed_progression {
        gap: 12px;
    }

    .progress_label {
        font-size: 16px;
    }
}
/* 
@media screen and (max-width: 400px) {
    .container {
        justify-content: space-evenly;
    }

    .label {
        font-size: 12px;
    }

    .location {
        font-size: 20px;
    }

    .total_progression {
        display: none;
    }
}

@media screen and (max-width: 650px) {
    .total_progression {
        display: none;
    }

    .detailed_progression {
        display: none;
    }
} */
