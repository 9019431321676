.btn {
    font-weight: 600;
    padding: 4px;
    font-size: 18px;
    line-height: 22px;
    cursor: pointer;
    border: none;
    background: none;
    color: #f4f3f0;
}

.btn:hover,
.btn:focus {
    color: #cfdf83;
}
