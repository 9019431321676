.container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 20px 24px;
    box-sizing: border-box;
    border: 2px solid #847e68;
    border-radius: 16px;
    background-color: transparent;
}

.progress {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-right: 24px;
}

.progress_text {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
}

.progress_text > img {
    height: 20px;
    margin-right: 4px;
}

.progress_text {
    font-weight: 500;
    font-size: 18px;
}

.progress_text > p > b {
    color: #e65402;
}

.progress_bar {
    width: 100%;
    height: 24px;
    background-color: #cfdf83;
    border-radius: 10px;
    position: relative;
}

.completed_bar {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    background-color: #094c43;
    border-radius: 12px;
}

.badge img {
    opacity: 0.5;
}
