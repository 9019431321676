.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    box-sizing: border-box;
}

.green {
    position: absolute;
    top: 0px;
    width: 100vw;
    height: 140px;
    background-color: #094c43;
}

.title {
    position: absolute;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    width: 100vw;
    height: 90px;
    margin-top: 64px;
    background: url("../../assets/green_trim.svg");
}

.title div {
    font-weight: 700;
    font-size: 32px;
    color: #fffef9;
}

.search_container {
    width: 100%;
    height: 160px;
    margin-top: 144px;
    padding: 20px;
    border-radius: 0px 0px 16px 16px;
    box-sizing: border-box;
    background-color: #fef6e7;
}

.search {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: 16px 0px;
    border-radius: 8px;
    border-bottom: 3px solid #2e2d2bcc;
    background-color: #e5dac4;
}

.search > img {
    margin: 0px 8px 0px 16px;
}

.search > input {
    width: 100%;
    height: 40px;
    padding-top: 4px;
    border: none;
    border-radius: inherit;
    background-color: inherit;
    box-sizing: border-box;
    font-size: 17px;
    color: #2e2d2b;
}

.search > input:focus {
    outline: none;
}

.filters {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 40px;
}

.filters > * {
    width: calc((100% - 12px) / 2);
    height: 100%;
    border: none;
    border-radius: 12px;
    box-sizing: border-box;
    font-size: 17px;
}

.filters > button {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #2e2d2b;
    background-color: #e5dac4;
}

.filters > select {
    padding: 0px 16px;
    border: none;
    color: #fffef9;
    appearance: none;
    background-color: #847e68;
    background-image: url("../../assets/white_down_arrow.svg");
    background-repeat: no-repeat;
    background-position-x: calc(100% - 12px);
    background-position-y: 50%;
}

.row {
    display: flex;
    align-items: center;
    width: 100%;
    height: 70px;
    border-bottom: 1px solid #b5b1a2;
}

.row > * {
    box-sizing: border-box;
}

.rank {
    flex: 0 0 64px;
    padding-left: 16px;
    color: #b5b1a2;
    font-weight: 700;
    font-size: 36px;
}

.badge {
    flex: 0 0 68px;
    padding: 12px;
}

.text {
    flex: 1 1 160px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 4px;
}

.text > p {
    font-size: 18px;
    color: #2e2d2b;
}

.text > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.text > div > p:first-child {
    width: calc(100vw - 204px);
    font-size: 16px;
    color: #9c9885;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.text > div > p:last-child {
    width: 72px;
    padding-left: 8px;
    box-sizing: border-box;
    color: #e65402;
    font-weight: 600;
    font-size: 18px;
}

p.highlight {
    font-weight: 700;
    color: #e65402;
}

.back_to_top_bottom {
    display: flex;
    justify-content: center;
    padding: 45px;
}

.back_to_top_bottom p {
    margin-right: 8px;
    color: rgba(132, 126, 104, 0.8);
    font-weight: 600;
    font-size: 16px;
}

.back_to_top {
    position: fixed;
    z-index: 1;
    top: 450px;
    right: 5px;
    display: flex;
    align-self: right;
    padding: 10px;
}
