.container {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.text {
    color: #094c43;
    font-size: 30px;
    font-weight: 600;
}
