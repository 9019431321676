.alert {
    width: 341px;
    box-sizing: border-box;
    padding: 16px 10px;
    margin: 8px 0;
    border-radius: 8px;
    background-color: #ebebeb;
    color: #000;
    text-align: center;
}

.success {
    background-color: #a8f0c6;
}

.error {
    background-color: #f7a7a3;
}

.close {
    margin-left: 15px;
    color: white;
    font-weight: bold;
    float: right;
    font-size: 22px;
    line-height: 20px;
    cursor: pointer;
    transition: 0.3s;
}

.close:hover {
    color: #2e2d2b;
}
