.container {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 90px;
    border-radius: 24px;
    box-sizing: border-box;
    background-color: #fdf1db;
    outline: 3px solid white;
    outline-offset: -8px;
}
.text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 0.5rem;
    padding-left: 24px;
}

.text > p {
    font-weight: 800;
    font-size: 18px;
    color: #2e2d2b;
}

.text > p:first-of-type {
    color: #e65402;
}

.points {
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100% - 16px);
    margin-right: 8px;
    padding: 16px;
    border-radius: 8px 16px 16px 8px;
    box-sizing: border-box;
    background-color: #fffef9;
}

.points > img {
    height: 24px;
    margin-right: 4px;
}

.multiple {
    position: absolute;
    z-index: 1;
    top: -16px;
    right: -16px;
    padding: 10px;
    font-weight: 800;
    font-size: 18px;
    border-radius: 100%;
    background-color: #cfdf83;
}

.container.disabled {
    background-color: #dfdfdf;
    opacity: 0.65;
    outline-color: #f5f5f5;
}

.container.disabled .points {
    background-color: #f5f5f5;
}
