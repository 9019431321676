.header {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding: 100px 80px 24px;
    box-sizing: border-box;
    background-image: url("../../assets/Trimtrim_bg.svg");
    background-repeat: no-repeat;
    background-size: 100% 45%;
}

.left_header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    width: 50%;
}

.left_header > p {
    font-size: 52px;
    font-weight: 700;
    color: #fffef9;
}

.buttons_section {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-top: 60px;
}

.buttons_section button {
    display: flex;
    align-items: center;
    width: 300px;
    height: 80px;
    margin-top: 24px;
    padding: 24px;
    border: none;
    border-radius: 27px;
    box-shadow: 0px 2px 0px #e0deca;
    font-weight: 700;
    font-size: 18px;
    text-align: left;
    color: #fffef9;
    cursor: pointer;
    background-color: #e65402;
}

.buttons_section button > img {
    width: 30px;
    height: 36px;
    margin-right: 16px;
}

.buttons_section > a > button {
    background-color: #cfdf83;
}

.badge_container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-width: 500px;
    width: 40%;
}

.badge_overview {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 32px;
    padding: 24px 36px;
    margin-bottom: 24px;
    box-sizing: border-box;
    background: #fdf1db;
    border-radius: 16px;
}

@media screen and (max-width: 1000px) {
    .header {
        padding: 100px 60px 24px;
    }

    .left_header {
        align-items: center;
    }

    .left_header > p {
        font-size: 48px;
    }

    .buttons_section {
        align-items: center;
    }

    .buttons_section button {
        width: 280px;
        font-size: 18px;
    }

    .badge_container {
        width: 320px;
    }
}
