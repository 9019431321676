.container {
    width: 100%;
    padding: 80px 80px 40px;
    box-sizing: border-box;
}

.container > div {
    display: flex;
    align-items: center;
}

.container img {
    height: 28px;
    margin-left: 12px;
}

.container > h1 {
    color: #2e2d2b;
}

.container > p {
    color: #2e2d2b;
}

@media screen and (max-width: 600px) {
    .container {
        padding: 88px 40px 60px;
    }

    .container img {
        height: 24px;
    }

    h1 {
        font-size: 24px;
    }
}
