@media screen and (min-width: 601px) {
    .container {
        background-image: url("../../assets/stacked_steps.svg");
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .container > img {
        display: none;
    }
}

@media screen and (max-width: 600px) {
    .container {
        position: fixed;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        height: 100%;
        background-color: #e65402;
        background-image: url("../../assets/orange_stripes.svg");
    }

    .container > img {
        position: fixed;
        top: 87%;
        width: 100%;
    }
}

.card {
    max-width: 360px;
    padding: 16px;
    margin: 16px;
    background: white;
    border-radius: 20px;
}

.header {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.back {
    width: 48px;
    height: 48px;
    border: none;
    border-radius: 100%;
    background-color: white;
    cursor: pointer;
}

.logo {
    width: 80%;
    max-width: 280px;
}

.wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem;
    gap: 32px;
}

.title {
    margin-bottom: 8px;
    color: #2e2d2b;
    font-weight: bold;
    font-size: 24px;
}

.instructions {
    color: #847e68;
    font-weight: 500;
}

.sendAgainBtn {
    background-color: transparent;
    border-radius: 30px;
    border: 3px solid #847e68;
    box-shadow: 0px 2px 0px #e0deca;
    height: 40px;
    width: 100%;
    color: #847e68;
    font-weight: 600;
    font-size: 16px;
    cursor: pointer;
}
