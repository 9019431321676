.wrapper {
    position: fixed;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-image: url("../../assets/stacked_steps.svg");
}

.wrapper p {
    font-size: 22px;
    color: #177a36;
    font-weight: 600;
    letter-spacing: 0.1rem;
}

.spinner {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
}
.spinner div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 8px solid #177a36;
    border-radius: 50%;
    animation: spinner 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #177a36 transparent transparent transparent;
}
.spinner div:nth-child(1) {
    animation-delay: -0.45s;
}
.spinner div:nth-child(2) {
    animation-delay: -0.3s;
}
.spinner div:nth-child(3) {
    animation-delay: -0.15s;
}
@keyframes spinner {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
