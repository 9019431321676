.container {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: calc(100% - 48px);
    height: calc(100vh - 48px);
    margin: 0px 24px;
    padding: 16px 32px;
    background: #fdf1db;
    border-radius: 16px;
}

.location_container {
    box-sizing: inherit;
    background: #fffef9;
    border-radius: 16px;
    padding: 18px 80px 12px;
    margin-top: 16px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.module {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    align-items: center;
    gap: 12px;
    width: 50%;
}

.module svg {
    min-width: 25px;
    min-height: 30px;
    max-width: 25px;
    max-height: 30px;
}

.text_container {
    display: flex;
    flex-direction: column;
    text-align: left;
    min-width: 0;
    position: relative;
}

.label {
    margin: 0;
    padding: 0;
    white-space: nowrap;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    color: rgba(46, 45, 43, 0.6);
}

.info {
    margin: 0;
    padding: 0;
    white-space: nowrap;
    font-weight: 700;
    font-size: 26px;
    line-height: 49px;
    color: #2e2d2b;
    text-overflow: ellipsis;
    overflow: hidden;
}

@media screen and (max-width: 975px) {
    .label {
        font-size: 14px;
    }

    .info {
        font-size: 24px;
    }

    .module svg {
        transform: translateY(-10px) scale(0.75);
    }
}

@media screen and (max-width: 750px) {
    .module {
        gap: 4px;
    }

    .module svg {
        transform: translateY(-10px) scale(0.5);
    }

    .info {
        font-size: 16px;
    }
}
