.container {
    z-index: 200;
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: white;
    overflow: hidden;
}

.buttons {
    z-index: 201;
    position: absolute;
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding-top: 16px;
    pointer-events: none;
}

.buttons button {
    padding: 8px 16px;
    font-size: 18px;
    opacity: 50%;
    color: #fffef9;
    background-color: #2e2d2b;
    border-radius: 18px;
    border: none;
    pointer-events: all;
}

.buttons .selected {
    opacity: 100%;
}

#exit {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    width: 52px;
    height: 52px;
}

::-webkit-scrollbar {
    -webkit-appearance: none;
}
