.container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin: 16px 0px;
}

.carousel {
    width: 100%;
    height: 300px;
    margin-bottom: 24px;
    overflow: visible;
}

.slide {
    width: 100%;
}

.slide > div {
    width: calc(100% - 8px);
    margin: 0px 4px 8px;
}
