.container {
    position: relative;
    display: inline-block;
    box-sizing: border-box;
    width: 48px;
    height: 48px;
}

.background {
    position: relative;
    width: 100%;
    height: 100%;
    border: 2px solid #fffef9;
    border-radius: 100%;
    box-sizing: border-box;
    background-color: #2e2d2b;
    background-clip: content-box;
    cursor: pointer;
}

.background:hover {
    border-color: #cfdf83;
}

.background:hover .profile_text {
    color: #cfdf83;
}

.profile_text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 0;
    margin: 0;
    font-weight: 600;
    font-size: 24px;
    color: #fffef9;
}

.dropdown_content {
    display: none;
    position: absolute;
    z-index: -1;
    right: 0;
    transform: translateY(-45%);
    padding: 48px 4px 4px;
    border-radius: 8px;
    background-clip: content-box;
}

.dropdown_content button {
    display: block;
    padding: 12px 16px;
    background-color: #fffef9;
    color: #2e2d2b;
    text-decoration: none;
    border: none;
    border-radius: 8px;
    font-weight: 550;
    font-size: 18px;
    cursor: pointer;
}

.dropdown_content button:hover {
    background-color: #f1f1f1;
}

.container:hover .dropdown_content {
    display: block;
}
