.nav {
    box-sizing: border-box;
    font-family: "Inter", sans-serif;
    position: fixed;
    top: 0;
    z-index: 100;
    display: flex;
    justify-content: space-between;
    width: 100vw;
    height: 64px;
    padding: 8px 28px;
    background: #094c43;
}

.left_container {
    display: flex;
    align-items: center;
}

.left_container > img {
    width: 40px;
}

.navbtns {
    display: flex;
    gap: 48px;
    margin-left: 60px;
}

.right_container {
    display: flex;
    align-items: center;
    gap: 30px;
}

@media screen and (max-width: 525px) {
    .nav {
        padding: 8px;
    }

    .navlinks {
        min-width: 175px;
        margin-left: 16px;
        gap: 0;
    }

    .right_container {
        gap: 16px;
    }
}
