.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 1200px;
    margin: 32px auto;
    padding: 32px;
    box-sizing: border-box;
}

.title {
    align-self: flex-start;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}

.title div {
    font-weight: 700;
    font-size: 48px;
    color: #2e2d2b;
}

.search_container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    width: 100%;
    height: 40px;
    margin: 32px 0px;
    box-sizing: border-box;
}

.search_container > button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 170px;
    height: 100%;
    border: none;
    border-radius: 12px;
    background-color: #847e68;
    color: #fffef9;
    font-size: 18px;
}

.search_container > select {
    padding: 0px 16px;
    width: 170px;
    height: 100%;
    border: none;
    border-radius: 12px;
    appearance: none;
    background-color: #847e68;
    background-image: url("../../assets/white_down_arrow.svg");
    background-repeat: no-repeat;
    background-position-x: calc(100% - 12px);
    background-position-y: 50%;
    color: #fffef9;
    font-size: 18px;
    outline: none;
}

.search {
    flex: 1 1 170px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    margin: 16px 0px;
    border-radius: 8px;
    border-bottom: 3px solid #2e2d2bcc;
    background-color: #e5dac4;
}

.search > img {
    margin: 0px 8px 0px 16px;
}

.search > input {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    border: none;
    border-radius: inherit;
    background-color: inherit;
    font-size: 18px;
    color: #2e2d2b;
}

.search > input:focus {
    outline: none;
}

.header {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    height: 60px;
    box-sizing: border-box;
    border-radius: 16px;
    background: #e0deca61;
}

.header > div {
    box-sizing: border-box;
    font-weight: 600;
    font-size: 18px;
    color: #847e68;
}

.row {
    display: flex;
    align-items: center;
    width: 100%;
    height: 80px;
}

.row > * {
    box-sizing: border-box;
}

/* .rank {
    flex: 0 0 80px;
    padding-left: 16px;
    color: #b5b1a2;
    font-weight: 700;
    font-size: 36px;
} */

.badge {
    flex: 0 0 68px;
    padding: 12px;
}

.name {
    width: calc((100% - 268px) * 0.4);
    margin-right: 16px;
    overflow: hidden;
}

.group {
    display: -webkit-box;
    width: calc((100% - 268px) * 0.6);
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    line-height: 30px;
    max-height: 60px;
}

.points {
    flex: 0 0 72px;
    /* margin-left: 32px; */
    margin-left: 112px;
    box-sizing: border-box;
    color: #e65402;
    font-weight: 600;
    font-size: 18px;
}

.points_header {
    position: absolute;
    right: 20px;
    display: flex;
    align-items: center;
    box-sizing: border-box;
}

.points_header > img {
    width: 20px;
    margin-right: 6px;
}

.highlight {
    font-weight: 700;
    color: #e65402;
}
