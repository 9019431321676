@media screen and (min-width: 601px) {
    .container {
        background-image: url("../../assets/stacked_steps.svg");
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .container > img {
        display: none;
    }

    .back {
        position: absolute;
        top: 44px;
        left: 44px;
        width: 48px;
        height: 48px;
        border: none;
        border-radius: 100%;
        background-color: #efeee1;
        cursor: pointer;
    }

    .logo {
        max-width: 100%;
        width: 300px;
        padding-top: 32px;
    }
}

@media screen and (max-width: 600px) {
    .container {
        position: fixed;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        height: 100%;
        background-color: #e65402;
        background-image: url("../../assets/orange_stripes.svg");
    }

    .container > img {
        position: fixed;
        top: 87%;
        width: 100%;
    }

    .header {
        display: flex;
        justify-content: space-around;
        align-items: center;
    }

    .back {
        width: 48px;
        height: 48px;
        border: none;
        border-radius: 100%;
        background-color: #efeee1;
        cursor: pointer;
    }

    .logo {
        align-self: flex-end;
        max-width: 75%;
        width: 300px;
        padding: 8px 8px 0px;
    }
}

.card {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 550px;
    width: 90%;
    min-height: 408px;
    margin: 16px;
    background: #fffef9;
    border-radius: 16px;
}

.card .title {
    color: #094c43;
    font-size: 30px;
    font-weight: bold;
    text-align: center;
    padding: 8px 0px 16px;
    margin: 0;
}

.card > p {
    width: 80%;
    color: #847e68;
    text-align: center;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
}

.card > a {
    position: relative;
    display: inline-block;
    margin: 12px 0px 18px;
    color: #094c43;
    font-weight: 500;
    font-size: 18px;
}

.card > a:before {
    position: absolute;
    height: 2px;
    bottom: -4px;
    width: 100%;
    content: "";
    background-color: #094c43;
    transition: width 0.3s ease-in-out;
}

.card > a:hover:before {
    width: 0;
}
