.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 92px 20px 20px;
}

.background {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: -1;
    height: 265px;
    border: none;
    background-color: #094c43;
}

.overview_container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 248px;
    padding: 18px 32px 28px;
    box-sizing: border-box;
    border-radius: 16px;
    background-color: #fdf1db;
}

.overview_container > p {
    line-height: 40px;
    font-weight: 1000;
    font-size: 36px;
    color: #094c43;
}

.badge {
    margin: 32px 0px 54px;
}

.container > a {
    width: 100%;
}

.event_button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 60px;
    margin: 36px 0px;
    margin-bottom: 15px;
    padding: 24px;
    border: none;
    border-radius: 30px;
    box-shadow: 0px 2px 0px #e0deca;
    font-weight: 600;
    font-size: 18px;
    color: #fffef9;
    background-color: #cfdf83;
}

.event_button > img {
    margin-right: 8px;
}

.buttons {
    display: flex;
    justify-content: space-between;
    width: 100%;
    gap: 12px;
}

.buttons > button {
    flex: 1 1 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 0px;
    height: 48px;
    border: none;
    border-radius: 30px;
    box-sizing: border-box;
    background-color: #e65402;
    font-size: 16px;
    font-weight: 600;
    color: #fffef9;
    cursor: pointer;
}

.buttons > button > img {
    width: 18px;
    margin-right: 8px;
}

.visited {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100vw;
    margin: 30px 0px;
    background-image: url("../../assets/login_trim.svg");
    background-size: cover;
    background-repeat: no-repeat;
}

.visited > p {
    font-weight: 700;
    font-size: 24px;
    color: #2e2d2b;
    margin-top: 50px;
    margin-bottom: 30px;
}

.radial_container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    width: 80%;
    height: 152px;
    margin-bottom: 30px;
    background: #fffef9;
    border-radius: 16px;
}

.location {
    font-weight: 500;
    font-size: 16px;
    text-align: center;
    padding-top: 5px;
    color: #847e68;
}

.total {
    color: #2e2d2b;
}

.highlight_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 30px;
    background: #fffef9;
    border-radius: 16px;
    width: 80%;
    height: 72px;
}

.title {
    color: #2e2d2b;
    font-weight: 700;
    font-size: 18px;
    margin-left: 30px;
}

.content {
    color: #847e68;
    font-weight: 700;
    font-size: 24px;
    margin-left: 30px;
}

.footer_button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80vw;
    height: 60px;
    margin-bottom: 24px;
    border: none;
    border-radius: 40px;
    box-shadow: 0px 2px 0px #e0deca;
    background-color: #e65402;
}

.footer_button > p {
    font-weight: 600;
    font-size: 20px;
    text-align: left;
    color: #fffef9;
}

.footer_button > img {
    width: 30px;
    height: 36px;
    margin-right: 10px;
}

.info {
    height: 92px;
    margin-bottom: 48px;
}

.full_width_button {
    width: 100%;
    margin-top: 0;
    margin-bottom: 10px;
    font-size: 16px;
    background-color: #e65402;
}

.full_width_button > img {
    width: 20px;
    height: auto;
}