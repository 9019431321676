.container {
    background: #fffef9;
    border-radius: 16px;
    height: 100%;
    width: 100%;
    z-index: 2;
}

.map_container {
    height: 100%;
    width: 100%;
    position: relative;
    z-index: 0;
}

.background {
    height: 100%;
    width: 100%;
    position: relative;
    z-index: 100;
}

.overlay {
    background-color: rgba(0, 0, 0, 0.65);
    height: 100%;
    width: 100%;
    position: absolute;
    z-index: 100000;
    display: flex;
    justify-content: center;
    align-items: center;
}

.loading {
    color: rgba(255, 255, 255, 0.85);
    font-size: 24px;
}

.loading:after {
    overflow: hidden;
    display: inline-block;
    vertical-align: bottom;
    -webkit-animation: ellipsis steps(4, end) 2000ms infinite;
    animation: ellipsis steps(4, end) 2000ms infinite;
    content: "\2026"; /* ascii code for the ellipsis character */
    width: 0px;
}

.legend {
    position: absolute;
    top: 4px;
    right: 4px;
    padding: 8px;
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    z-index: 99; /* a value less than overlay's z-index */
}

@media screen and (max-width: 600px) {
    .legend {
        display: none;
    }
}

.visited,
.not_visited {
    text-align: left;
}

.visited::before {
    content: "";
    display: inline-block;
    height: 12px;
    width: 12px;
    background-color: orangered;
}

.not_visited::before {
    content: "";
    display: inline-block;
    height: 12px;
    width: 12px;
    background-color: black;
}

@keyframes ellipsis {
    to {
        width: 1.25em;
    }
}

@-webkit-keyframes ellipsis {
    to {
        width: 1.25em;
    }
}
