.nav {
    position: fixed;
    z-index: 100;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 72px;
    background: #094c43;
}

.hamburger {
    position: fixed;
    left: 20px;
    height: 24px;
}

.logo {
    height: 40px;
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    width: 100vw;
    height: 100%;
}

.menu {
    position: fixed;
    z-index: 2;
    display: flex;
    flex-direction: column;
    width: 75%;
    height: 100%;
    padding: 92px 32px 32px;
    box-sizing: border-box;
    box-shadow: 0px 0px 60px rgba(46, 45, 43, 0.6);
    background: #fffef9;
}

.menu > img {
    position: fixed;
    top: 24px;
    left: 24px;
    height: 28px;
    filter: invert(50%);
}

.profile {
    align-self: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 160px;
    margin-bottom: 36px;
}

.name {
    margin: 8px 0px 4px;
    font-weight: 700;
    font-size: "18px";
    color: #847e68;
}

.email {
    font-weight: 500;
    font-size: "16px";
    color: #847e68;
}

.menu_item {
    display: flex;
    align-items: center;
    padding-bottom: 20px;
    font-weight: 700;
    font-size: 18px;
    color: #847e68;
}

.menu_item > img {
    width: 24px;
    height: 28px;
    margin: 0px 12px 0px 0px;
}

.signout {
    align-self: center;
    display: flex;
    align-items: center;
    margin: auto 0px 48px;
    font-weight: 700;
    font-size: 18px;
    color: #847e68;
}

.signout > img {
    padding-left: 8px;
}

.background {
    position: fixed;
    z-index: 1;
    width: 100%;
    height: 100%;
    border: none;
    background: gray;
    opacity: 0.6;
}
