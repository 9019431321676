@media screen and (min-width: 601px) {
    .container {
        background-image: url("../../assets/stacked_steps.svg");
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
    }

    .container > img {
        display: none;
    }

    .card > button:hover {
        background: #c54800;
    }

    .card > .sending:hover {
        background: #e65402;
        cursor: wait;
    }
}

@media screen and (max-width: 600px) {
    .container {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 100%;
        background-color: #e65402;
        background-image: url("../../assets/orange_stripes.svg");
    }

    .container > img {
        position: fixed;
        top: 87%;
        width: 100%;
    }
}

.card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 550px;
    width: 90%;
    min-height: 408px;
    margin: 16px;
    background: #fffef9;
    border-radius: 16px;
}

.card > img {
    max-width: 100%;
    width: 300px;
    padding-top: 32px;
}

.title {
    color: #094c43;
    font-size: 30px;
    font-weight: bold;
    text-align: center;
    padding: 8px 0px 16px;
    margin: 0;
}

.input_container {
    width: 80%;
    max-width: 341px;
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.input_container > input {
    padding: 10px;
    font-size: 15px;
    height: 34px;
    background: #ececec;
    border-radius: 10px;
    border: 1px solid #dbdbdb;
}

.input_error {
    border: 2px solid #c30017;
    background-color: #c300171a;
}

.input_container > p {
    color: #c30017;
    font-size: 0.9rem;
    margin: 4px;
}

.card > button {
    width: 80%;
    max-width: 341px;
    height: 50px;
    margin: 20px 0px;
    border-radius: 10px;
    border: none;
    background: #e65402;
    color: white;
    font-weight: bold;
    font-size: 18px;
    cursor: pointer;
}

.sending {
    filter: brightness(0.75);
}

.container {
    height: calc(var(--vh, 1vh) * 100);
}
