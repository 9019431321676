.container {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 100px 80px 24px;
    box-sizing: border-box;
}

.container > p {
    margin-bottom: 24px;
    font-weight: 700;
    font-size: 42px;
    color: #2e2d2b;
}

.title {
    margin: 100px 80px -80px;
    font-weight: 700;
    font-size: 48px;
}

.subtitle {
    display: flex;
    align-items: center;
}

.subtitle > p {
    margin-right: 16px;
    font-weight: 700;
    font-size: 28px;
}

.cards {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 12px 24px;
    margin: 32px 0px 16px;
}

.cards > div {
    flex: 1 1 330px;
}

.cards > span {
    font-weight: 500;
    font-size: 18px;
    color: #2e2d2b;
}
