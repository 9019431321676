.eventsList {
    display: flex;
    flex-direction: column;
    /* max-width: 500; if we want to have events in the middle and not take up whole screen*/
    margin: 0 auto;
    padding-top: 60px;
}

.eventItem {
    display: flex;
    align-items: center;
    padding: 10px;
    border-bottom: 1px solid #ccc;
}

.eventDetails {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.eventDate {
    font-size: 16px;
    margin-bottom: 5px;
}

.eventInfo {
    font-size: 14px;
}

.eventLocation {
    color: grey;
    margin-top: 4px;
}

.eventRole {
    color: #e65402;
    font-weight: bold;
    margin-top: 24px;
}

.eventHeader {
    display: none;
}

.green {
    position: absolute;
    top: 0px;
    width: 100vw;
    height: 140px;
    background-color: #094c43;
}

.title {
    position: absolute;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    width: 100vw;
    height: 90px;
    margin-top: 64px;
    background: url("../../assets/green_trim.svg");
}

.title div {
    font-weight: 700;
    font-size: 32px;
    color: #fffef9;
}

@media (max-width: 600px) {
    .eventRole {
        font-size: 90%;
        width: calc(100vw - 204px);
        font-size: 16px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        text-align: right;
    }

    .eventLocation {
        width: calc(100vw - 204px);
        font-size: 16px;
        color: #9c9885;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }

    .eventInfo {
        width: calc(100vw - 204px);
        font-size: 16px;
        color: #9c9885;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }

    .eventHeader {
        display: block;
    }

    .eventsList {
        padding-top: 140px;
    }

    .eventItem:first-child {
        margin-top: 10px;
    }
}
