.dashboard {
    display: flex;
    position: relative;
    margin: inherit;
}

.dashboard svg {
    height: 100%;
    width: 100%;
}

.bg {
    fill: none;
    stroke: #2e2d2b;
}

.meter {
    fill: none;
    stroke-linecap: round;
    stroke: #e65402;
    transform: rotate(-90deg);
    transform-origin: 50% 50%;
}

.proportion {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-weight: 700;
    font-size: 22px;
    line-height: 29px;
    color: #e65402;
}

/* .proportion span {
    color: #2e2d2b;
} */
