.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
    overflow: hidden;
}

.container > p {
    margin-bottom: 24px;
    font-weight: 700;
    font-size: 42px;
    color: #2e2d2b;
}

.container > span {
    margin: 24px 0px;
    text-align: center;
    font-weight: 500;
    font-size: 18px;
    color: #2e2d2b;
}

.green {
    position: absolute;
    z-index: -1;
    top: 0px;
    width: 100vw;
    height: 140px;
    background-color: #094c43;
}

.title {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    width: 100vw;
    height: 90px;
    margin-top: 64px;
    background: url("../../assets/green_trim.svg");
}

.title div {
    font-weight: 700;
    font-size: 32px;
    color: #fffef9;
}

.subtitle {
    display: flex;
    align-items: center;
}

.subtitle > p {
    margin-right: 16px;
    font-weight: 700;
    font-size: 28px;
}

.cards {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 12px 24px;
    margin: 24px 0px;
}

.cards > div {
    flex: 1 1 330px;
}
