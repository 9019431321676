@media screen and (max-width: 600px) {
    .popup {
        display: none;
    }
}

.popup > :first-child {
    border-radius: 0;
}

.popup > :first-child > :first-child {
    margin: 12px;
}

.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 250px;
}

.location {
    font-size: 18px;
    font-weight: 600;
    margin: 0 !important;
}

.address {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.75);
    display: flex;
    align-items: center;
    gap: 6px;
}

.wrapper {
    display: flex;
    align-items: center;
    gap: 7px;
}

.not_visited {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.75);
    margin: 0 !important;
}

.visited {
    font-size: 14px;
    margin: 0 !important;
    color: #15b4b4;
}
