@media screen and (min-width: 601px) {
    .container {
        background-image: url("../../assets/stacked_steps.svg");
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
    }

    .container > img {
        display: none;
    }

    .wrapper > button:hover {
        background: #c54800;
    }

    .wrapper > .sending:hover {
        background: #e65402;
        cursor: wait;
    }
}

@media screen and (max-width: 600px) {
    .container {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 100%;
        background-color: #e65402;
        background-image: url("../../assets/orange_stripes.svg");
    }

    .container > img {
        position: fixed;
        top: 87%;
        width: 100%;
    }
}

.card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-width: 296px;
    max-width: 400px;
    padding: 16px;
    margin: 16px;
    background: white;
    border-radius: 20px;
}

.header {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
}

.back {
    width: 48px;
    height: 48px;
    border: none;
    border-radius: 100%;
    cursor: pointer;
}

.logo {
    width: 80%;
    max-width: 280px;
}

.wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem;
    gap: 12px;
}

.title {
    align-self: flex-start;
    margin-bottom: 8px;
    color: #2e2d2b;
    font-weight: bold;
    font-size: 24px;
}

.instructions {
    color: #847e68;
    font-weight: 500;
}

.instructions > span {
    white-space: nowrap;
}

.input {
    width: 100%;
    margin: 1rem 0;
}

.input > div {
    justify-content: center;
    gap: 8px;
    width: 100%;
    --ReactInputVerificationCode-itemWidth: 38px;
    --ReactInputVerificationCode-itemHeight: 44px;
}

.input > div > div {
    max-width: 40px;
    border-radius: 8px;
}

.invalid > div > div {
    border: 1px solid #c30017 !important;
    background-color: #c300171a !important;
    color: #c30017 !important;
}

.input > p {
    color: #c30017;
    font-size: 0.9rem;
    margin: 4px;
}

.wrapper > button {
    background-color: #e65402;
    border-radius: 30px;
    border: none;
    box-shadow: 0px 2px 0px #e0deca;
    width: 100%;
    height: 40px;
    color: #fff;
    font-weight: 600;
    font-size: 16px;
    cursor: pointer;
}

.sending {
    filter: brightness(0.75);
}
