.badge {
    display: flex;
    flex-direction: column;
    align-items: center;
    user-select: none;
}

.badge > img {
    height: 100%;
}

.badge > span {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 74px;
    height: 28px;
    margin-top: -26px;
    padding: 4px;
    border-radius: 18px;
    background-color: #e65402;
    font-weight: 700;
    font-size: 16px;
    color: #fffef9;
}

.badge > p {
    margin-top: 8px;
    font-weight: 700;
    font-size: 18px;
    text-align: center;
    color: #847e68;
}
